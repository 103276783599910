import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card, Select, message, Row, Col, Drawer, Button } from "antd";
import { useParams } from "react-router-dom";

import { useGlobalContext } from "../../lib/storage";
import Logo from "../../assets/images/cwsuiteWhite.svg";
// import collapseMenuIcon from "../../assets/images/collapseMenuIcon.svg";
// import expandMenuIcon from "../../assets/images/expandMenuIcon.svg";
// import hideMenuIcon from "../../assets/images/hideMenuIcon.svg";
// import showMenuIcon from "../../assets/images/showMenuIcon.svg";
import CloseIcon from "../../assets/images/closeButton.svg";
import Search from "../../assets/images/searchIcon.svg"
import {
  getAdminMenuListForDynamicLogos,
} from "../../services/generic";

// const { recordId } = useParams();

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import "./menu.css";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Option,OptGroup } = Select;

const toggleFullHideButtonInactive = {
  position: "absolute",
  bottom: "-4px",
  backgroundColor: "#FFFFFF",
  color: "white",
  height: "30px",
  width: "35px",
  borderRadius: "0px 50px 50px 0px",
  textAlign: "center",
  left: "59px",
  zIndex: "9",
  fontWeight: "bold",
  cursor: "pointer",
  padding: "5px",
};

const toggleFullHideButtonActive = {
  ...toggleFullHideButtonInactive,
  left: "0px",
};

const SideMenu = (props) => {
  // const textInput = React.useRef(null);

  const { globalStore, setGlobalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const { menuToggle, setMenuToggle, fullMenuToggle,showToggler, setFullMenuToggle } = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();
  let lowerSubMenu = JSON.parse(localStorage.getItem("lowerSubMenu"));

  // const [menuToggle, setMenuToggle] = useState(false);
  // const [fullMenuToggle, setFullMenuToggle] = useState(false);
  // const [siderActiveStyles, setSiderActiveStyles] = useState({});
  const { drawerFlag, onClose } = props;
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [autoFocusSearch,setautoFocusSearch] = useState(true)
  const [menuIdFromUrl,setMenuIdFromUrl] = useState(null)
  const [cwLogo, setCWLogo] = useState(null);
  const [clientLogo, setClientLogo] = useState(null);

  useEffect(() => {
    getCwLogos()
    const menuIdData = history.location.pathname.split('/')
    if(menuIdData.length > 0){
      setMenuIdFromUrl(menuIdData[3])
    }
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
            item.children.map((subItem, subIndex) => {
              if (subItem.children && subItem.children.length > 0) {
                subItem.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
              }
              // item.children[subIndex] = subItem;
              return null;
            });
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }

  }, [menuList]);

  const getCwLogos = async () =>{
    const logosResponse = await getAdminMenuListForDynamicLogos(globalStore.userData.role_id);
    const cwLogo = logosResponse.cwLogo
    const clientLogo = logosResponse.clientLogo
    setCWLogo(cwLogo)
    setClientLogo(clientLogo)
  }
  
  const selectMenuToNavigate = (data) => {
    localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.key));
    // console.log(data)
    if (data.type === "Report") {
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else {
      message.warning("Not Available");
    }
  };
  /* const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }; */

  const showDrawer = () => {
    setVisible(true);
  };

  const NavigateToMenu = (menuType, menuId, menuTitle) => {
    setMenuIdFromUrl(menuId)
    let navigationUrl;
    switch (menuType) {
      case "Report":
        navigationUrl = `/reports/report/${menuId}`;
        break;
      case "Dashboard":
        navigationUrl = `/analytics/dashboard/${menuId}`;
        break;
      case "Generic":
        navigationUrl = `/window/list/${menuId}`;
        break;
      case "Custom":
        navigationUrl = `/others/window/${menuId}`;
        break;
      case "GenericNew":
        navigationUrl = `/window/${menuId}/NEW_RECORD`;
        break;
      case "App":
        navigationUrl = `/App/window/${menuId}`;
        break;
      default:
        message.warning("Not Available");
        break;
    }
    if (navigationUrl) {
      if (userPreferences.enableMultiTab === "Y") {
        const prevWindowTabs = [...windowTabs];
        if (prevWindowTabs.findIndex((tab) => tab.url === navigationUrl) < 0) {
          const newWindowTab = {
            url: navigationUrl,
            title: menuTitle,
            content: null,
          };
          setGlobalStore({ windowTabs: [...prevWindowTabs, newWindowTab] });
          history.push(navigationUrl);
        } else {
          message.warning("Tab Already Active");
        }
      } else {
        history.push(navigationUrl);
      }
    }
    props.onClose();
  };

  const selectMenu = (value, data) => {
    const valueData = value.split("@");
    if (valueData.length > 0) {
      NavigateToMenu(valueData[1], data.key, data.children);
    }
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const menuToggleFromSerach = () =>{
    setMenuToggle((menuToggle) => !menuToggle)
    // setTimeout(() => {
    //   setautoFocusSearch(true)
    // }, 1000);
  }

  let lowerSubMenuItem =JSON.parse(localStorage.getItem("lowerSubMenuItem"))


  return (
    <div className="responsiveSideMenu" style={{backgroundColor:'#E1E3E7',marginTop:'0.5px'}}>
      {drawerFlag && !menuToggle ?(''):
      ( <Sider  collapsed={menuToggle} onCollapse={null} style={{backgroundColor:'#E1E3E7'}}  className={menuToggle ? (fullMenuToggle ? "fullmenu-active" : "fullmenu-inactive") : null}>
        {/* <Card style={Themes.sideMenu.sideMenuCard}> */}
            <Scrollbars
              style={{
                marginLeft:'1.5px',
                height:'80vh'
              }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              thumbSize={90}
              renderView={renderView}
              renderThumbHorizontal={renderThumb}
              renderThumbVertical={renderThumb}
            >
              <Menu selectedKeys={lowerSubMenuItem} className="sideMenu" style={{backgroundColor:'#E1E3E7', marginLeft:'1.5px',marginRight:'1.5px',paddingTop:'9px'}} mode="vertical" >
              {lowerSubMenu ?(lowerSubMenu.map((menuList) => {
                  setMenuToggle(false)
                  return (
                    <Menu.Item key={menuList.key} onClick={()=>selectMenuToNavigate({type:menuList.type,id:menuList.id,key:menuList.key})}>{menuList.title}</Menu.Item>
                  )

                }))
              :(
                setMenuToggle(true) && setFullMenuToggle(true)
              )
              }
              </Menu>
            </Scrollbars>
            {/* </Card> */}
        </Sider>)}
       
    </div>
  );
};

export default SideMenu;
